@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=Exo+2&family=Hedvig+Letters+Sans&family=Indie+Flower&family=Libre+Franklin:wght@300&family=Montserrat&family=Mulish:wght@300&family=Nunito:wght@300&family=PT+Sans&family=Poppins:wght@300&family=Prompt:wght@300&family=Roboto:wght@300&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: $color-white;
  font-family: Mulish, sans-serif;
}


.food-section {
  background-color: rgb(240 240 240);
  padding: 50px;

  .food-title {
    font-size: $size-small;
    text-align: center;
    font-weight: 900;
    padding: $size-small;
    text-shadow: 5px 20px rgb(198 198 198 / 37.4%);
  }

  .food-container {
    @include d-flex(flex, space-between, center);

    padding: 30px 100px;

    .food-div {
      max-width: 380px;
      width: 100%;
      border-radius: 8px;
      background-color: $color-white;
      box-shadow: 5px 5px 30px $primary-color;
      transition: 0.3s;

      .food-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .text-food-div {
        padding: $size-small - 15px;

        h3 {
          margin-bottom: 20px;
          font-weight: 600;
        }

        p {
          font-size: 15px;
          margin-bottom: 15px;
        }

        i {
          color: $color-yellow;
        }
      }

      &:hover {
        transform: translateY(-17px);
      }
    }
  }

  @media (width <= 1300px) {
    .food-container {
      .food-div {
        max-width: 400px;
        width: 100%;
        margin-top: $size-small;
        transform: scale(0.95);
      }
    }
  }

  @media (width <= 1150px) {
    .food-container {
      flex-direction: column;

      .food-div {
        padding: 0;
        max-width: 500px;
        width: 100%;
        margin-top: $size-small;
      }
    }
  }

  @media (width <= 580px) {
    .food-title {
      font-size: $size-small - 20px;
    }

    .food-container {
      flex-direction: column;
      padding: 0;

      .food-div {
        margin-top: $size-small;
      }
    }
  }
}

